<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>Customers Registration</b-card-title>
      <!-- datepicker -->
      <div class="d-flex align-items-center">
        <feather-icon
          icon="CalendarIcon"
          size="16"
        />
        <!-- <flat-pickr
          v-model="rangePicker"
          :config="{ mode: 'range'}"
          class="form-control flat-picker bg-transparent border-0 shadow-none"
          placeholder="YYYY-MM-DD"
        /> -->
      </div>
      <!-- datepicker -->
    </b-card-header>

    <!-- chart -->
    <b-card-body v-if="data">
      <chartjs-component-bar-chart
        :height="400"
        :data="latestBarChart.data"
        :options="latestBarChart.options"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCardTitle,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import ChartjsComponentBarChart from './charts-components/ChartjsComponentBarChart.vue'
import chartjsData from './chartjsData'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    flatPickr,
    ChartjsComponentBarChart,
  },
  data() {
    return {
      chartjsData,
      data: '',
      rangePicker: ['2019-05-01', '2019-05-10'],
      latestBarChart: {
        data: {
          labels: ['7/12', '8/12', '9/12', '10/12', '11/12', '12/12', '13/12', '14/12', '15/12', '16/12', '17/12'],
          datasets: [
            {
              data: [275, 90, 190, 205, 125, 85, 55, 87, 127, 150, 230, 280, 190],
              backgroundColor: '#28dac6',
              borderColor: 'transparent',
            },
          ],
        },
        options: {
          elements: {
            rectangle: {
              borderWidth: 2,
              borderSkipped: 'bottom',
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          responsiveAnimationDuration: 500,
          legend: {
            display: false,
          },
          tooltips: {
            // Updated default tooltip UI
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 8,
            shadowColor: 'rgba(0, 0, 0, 0.25)',
            // backgroundColor: chartjsData.$themeColors.light,
            // titleFontColor: chartjsData.$themeColors.dark,
            // bodyFontColor: chartjsData.$themeColors.dark,
          },
          scales: {
            xAxes: [
              {
                display: true,
                gridLines: {
                  display: true,
                  color: 'rgba(200, 200, 200, 0.2)',
                  zeroLineColor: 'rgba(200, 200, 200, 0.2)',
                },
                scaleLabel: {
                  display: false,
                },
                ticks: {
                  fontColor: '#6e6b7b',
                },
              },
            ],
            yAxes: [
              {
                display: true,
                gridLines: {
                  color: 'rgba(200, 200, 200, 0.2)',
                  zeroLineColor: 'rgba(200, 200, 200, 0.2)',
                },
                ticks: {
                  stepSize: true,
                  min: 0,
                  max: 400,
                  fontColor: '#6e6b7b',
                },
              },
            ],
          },
        },
      },
    }
  },
  methods: {
    getData(){
      axios.get("/getCustomersCount")
      .then(response => {
        this.latestBarChart.data.labels = response.data.months;
        this.latestBarChart.data.datasets[0].data = response.data.customers;
        let maxItem = Math.max.apply(Math, response.data.customers) 
        let minItem = Math.min.apply(Math, response.data.customers) 
        this.latestBarChart.options.scales.yAxes[0].ticks.max = maxItem
        this.latestBarChart.options.scales.yAxes[0].ticks.min = minItem
        this.latestBarChart.options.scales.yAxes[0].ticks.stepSize = ((maxItem- minItem) / 5)
        this.data = response.data.months
      })

    },
  },
  mounted() {
    this.getData()
  }
  
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
