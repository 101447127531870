<template>
    <b-card
      no-body
    >
      <!-- title and dropdown -->
      <b-card-header class="pb-0">
        <b-card-title> Region </b-card-title>
      </b-card-header>
      <!--/ title and dropdown -->

      <b-card-body>

        <b-table
            small
            :fields="fields"
            :items="regions"
            :sticky-header="true"

        >
            <!-- A virtual column -->
            <template #cell(index)="data">
            {{ data.index + 1 }}
            </template>
    
            
    
            <!-- A custom formatted column -->
            <!-- <template #cell(Popularity)="data">
            <b-progress
                :value="data.value.value"
                max="100"
                :variant="data.value.variant"
                striped
            />
            </template> -->
    
            <!-- <template #cell(order_status)="data">
            <b-badge
                pill
                :variant="data.value.variant"
            >
                {{ data.value.status }}
            </b-badge>
            </template> -->
    
            <!-- A virtual composite column -->
            <!-- <template #cell(price)="data">
            {{ '$'+data.value }}
            </template> -->
    
            <!-- Optional default data cell scoped slot -->
            <!-- <template #cell()="data">
            {{ data.value }}
            </template> -->
        </b-table>
      </b-card-body>
    </b-card>
  </template>
  
  <script>
  import { BTable, BProgress, BBadge, BCard, BCardHeader, BCardTitle, BCardBody } from 'bootstrap-vue'
  
  export default {
    props: {
        regions: {
            type: Array,
            default: () => [],
        },
    },
    components: {
      BTable,
      BProgress,
      BBadge,
      BCard, 
      BCardHeader, 
      BCardTitle,
      BCardBody,
    },
    data() {
      return {
        fields: [
          // A virtual column that doesn't exist in items
          'index',
          // A column that needs custom formatting
          { key: 'name', label: 'Name' },
          { key: 'state.name', label: 'State' },
          { key: 'hospitals_count', label: 'Hospitals' },
          { key: 'pharmacies_count', label: 'Pharamcies' },
          
          // A regular column
        //   { key: 'Popularity', label: 'Popularity' },
          // A regular column
        //   { key: 'order_status', label: 'Order Status' },
          // A virtual column made up from two fields

        ],

      }
    },
  }
  </script>