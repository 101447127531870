<template>
  <b-card no-body v-if="data">
    <b-card-header>
      <b-card-title>Orders</b-card-title>

      <!-- datepicker -->
      <div class="d-flex align-items-center">
        <feather-icon
          icon="CalendarIcon"
          size="16"
        />
        <!-- <flat-pickr
          v-model="rangePicker"
          :config="{ mode: 'range'}"
          class="form-control flat-picker bg-transparent border-0 shadow-none"
          placeholder="YYYY-MM-DD"
        /> -->
      </div>
      <!-- datepicker -->
    </b-card-header>
    {{data.months}}
    <!-- chart -->
    <b-card-body v-if="data">
      <chartjs-component-line-chart
        
        :height="450"
        :data="lineAreaChart.data"
        :options="lineAreaChart.options"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BCardHeader, BCardTitle,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import ChartjsComponentLineChart from './charts-components/ChartjsComponentLineChart.vue'
import chartjsData from './chartjsData'
import { $themeColors } from '@themeConfig'

export default {
  
  components: {
    ChartjsComponentLineChart,
    BCard,
    BCardBody,
    BCardHeader,
    BCardTitle,
    flatPickr,
  },

  data() {
    return {
      data: '',
      chartjsData,
      rangePicker: ['2019-05-01', '2019-05-10'],
      isLoading: false,
      rangePicker: '',
      lineAreaChart: {
        options: {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            position: 'top',
            align: 'start',
            display: true,
            labels: {
              usePointStyle: true,
              padding: 15,
              boxWidth: 8,
            },
          },
          tooltips: {
            // Updated default tooltip UI
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 8,
            shadowColor: 'rgba(0, 0, 0, 0.25)',
            backgroundColor: $themeColors.light,
            titleFontColor: $themeColors.dark,
            bodyFontColor: $themeColors.dark,
          },
          scales: {
            xAxes: [
              {
                display: true,
                gridLines: {
                  color: 'transparent',
                  zeroLineColor: 'rgba(200, 200, 200, 0.2)',
                },
                scaleLabel: {
                  display: true,
                },
                ticks: {
                  fontColor: '#6e6b7b',
                },
              },
            ],
            yAxes: [
              {
                display: true,
                gridLines: {
                  color: 'transparent',
                  zeroLineColor: 'rgba(200, 200, 200, 0.2)',
                },
                ticks: {
                  stepSize: 5,
                  min: 0,
                  max: 100,
                  fontColor: '#6e6b7b',
                },
                scaleLabel: {
                  display: true,
                },
              },
            ],
          },
        },
        data: {
          labels: [
            '7/12',
            '8/12',
            '9/12',
            '10/12',
            '11/12',
            '12/12',
            '13/12',
            '14/12',
            '15/12',
            '16/12',
            '17/12',
            '18/12',
            '19/12',
            '20/12',
            '',
          ],
          datasets: [
            {
              label: 'Africa',
              data: [40, 55, 45, 75, 65, 55, 70, 60, 100, 98, 90, 120, 125, 140, 155],
              lineTension: 0,
              backgroundColor: '#84D0FF',
              pointStyle: 'circle',
              borderColor: 'transparent',
              pointRadius: 0.5,
              pointHoverRadius: 5,
              pointHoverBorderWidth: 5,
              pointBorderColor: 'transparent',
              pointHoverBackgroundColor: '#2c9aff',
              pointHoverBorderColor: $themeColors.light,
            },
            {
              label: 'Asia',
              data: [70, 85, 75, 150, 100, 140, 110, 105, 160, 150, 125, 190, 200, 240, 275],
              lineTension: 0,
              backgroundColor: '#2c9aff',
              pointStyle: 'circle',
              borderColor: 'transparent',
              pointRadius: 0.5,
              pointHoverRadius: 5,
              pointHoverBorderWidth: 5,
              pointBorderColor: 'transparent',
              pointHoverBackgroundColor: '#2c9aff',
              pointHoverBorderColor: $themeColors.light,
            },
            {
              label: 'Europe',
              data: [240, 195, 160, 215, 185, 215, 185, 200, 250, 210, 195, 250, 235, 300, 315],
              lineTension: 0,
              backgroundColor: '#4F5D70',
              pointStyle: 'circle',
              borderColor: 'transparent',
              pointRadius: 0.5,
              pointHoverRadius: 5,
              pointHoverBorderWidth: 5,
              pointBorderColor: 'transparent',
              pointHoverBackgroundColor: '#4F5D70',
              pointHoverBorderColor: $themeColors.light,
            },
          ],
        },
      },
    }
  },
  methods: {
    getData(){
      axios.get("/orderChart")
      .then(response => {
        
        this.lineAreaChart.data.labels = response.data.months

        this.lineAreaChart.data.datasets[0].label = 'Pendding'
        this.lineAreaChart.data.datasets[0].data  = response.data.pending

        this.lineAreaChart.data.datasets[1].label = 'Compated'
        this.lineAreaChart.data.datasets[1].data  = response.data.completed

        this.lineAreaChart.data.datasets[2].label = 'canceled'
        this.lineAreaChart.data.datasets[2].data  = response.data.cancel

        this.lineAreaChart.options.scales.yAxes[0].ticks.max = 200 
        // this.lineAreaChart.options.scales.yAxes[0].ticks.stepSize = 10 

        // console.log(Math.max(response.data.pending));
        // const max = Math.max(response.data.pending);

        // const index = arr.indexOf(max);
        // console.log(index); // 👉️ 3


        this.data = response.data.months
      })

    }
  },

   mounted() {
    
    this.getData()
    
    // console.log(chartjsData.lineAreaChart.data.labels)
    // chartjsData.lineAreaChart.data.labels = this.data.months
    // console.log(this.data.months);
  },

  // mounted() {
  //   console.log(this.data);
  //   chartjsData.lineAreaChart.data.labels = this.data.months
  // },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
