<template>
  <div>
    <b-card
    v-if="data"
    no-body
    class="card-statistics"
  >
    <b-card-header>
      <b-card-title>User Statistics</b-card-title>
      <!-- <b-dropdown
        no-caret
        right
        text="Last 7 Days"
        variant="transparent"
        class="chart-dropdown"
        toggle-class="p-50"
        size="sm"
      >
        <b-dropdown-item
          v-for="day in data.lastDays"
          :key="day"
        >
          {{ day }}
        </b-dropdown-item>
      </b-dropdown> -->
    </b-card-header>

    <b-card-body class="statistics-body">

      <b-row>

        <!-- company -->
        <b-col
          xl="3"
          sm="3"
          class="mb-2 mb-xl-0"
        >
          <b-media no-body>
            <b-media-aside
              class="mr-2"
            >
              <b-avatar
                size="48"
                variant="light-primary"
              >
                <feather-icon
                  size="24"
                  icon="GridIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{data.companyConut}}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                Companies
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>


        <!-- Pharmaceutical -->
        <b-col
          xl="3"
          sm="3"
          class="mb-2 mb-xl-0"
        >
          <b-media no-body>
            <b-media-aside
              class="mr-2"
            >
              <b-avatar
                size="48"
                variant="light-success"
              >
                <feather-icon
                  size="24"
                  icon="PackageIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ data.PharmaceuticalCount }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                Pharmaceuticals
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>


        <!-- State -->
        <b-col
          xl="3"
          sm="3"
          class="mb-2 mb-xl-0"
        >
          <b-media no-body>
            <b-media-aside
              class="mr-2"
            >
              <b-avatar
                size="48"
                variant="light-info"
              >
                <feather-icon
                  size="24"
                  icon="Navigation2Icon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ data.stateCount }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                States
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>


         <!-- Regions -->
         <b-col
         xl="3"
         sm="3"
         class="mb-2 mb-xl-0"
       >
         <b-media no-body>
           <b-media-aside
             class="mr-2"
           >
             <b-avatar
               size="48"
               variant="light-warning"
             >
               <feather-icon
                 size="24"
                 icon="SunsetIcon"
               />
             </b-avatar>
           </b-media-aside>
           <b-media-body class="my-auto">
             <h4 class="font-weight-bolder mb-0">
               {{ data.regionCount }}
             </h4>
             <b-card-text class="font-small-3 mb-0">
               Regions
             </b-card-text>
           </b-media-body>
         </b-media>
       </b-col>

        </b-row>

        </b-card-body>
        </b-card>

        <b-card
            v-if="data"
            no-body
            class="card-statistics"
        >
        <b-card-body>
            <b-card-header>
                <b-card-title>Statistics</b-card-title>
            </b-card-header>
        <b-row>


        <!-- hospital -->
        <b-col
          xl="3"
          sm="3"
          class="mb-2 mb-xl-0"
        >
          <b-media no-body>
            <b-media-aside
              class="mr-2"
            >
              <b-avatar
                size="48"
                variant="light-danger"
              >
                <feather-icon
                  size="24"
                  icon="BoxIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ data.hospitalCount }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                Hospitals
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>

        <!-- Doctors -->
        <b-col
          xl="3"
          sm="3"
          class="mb-2 mb-xl-0"
        >
          <b-media no-body>
            <b-media-aside
              class="mr-2"
            >
              <b-avatar
                size="48"
                variant="light-info"
              >
                <feather-icon
                  size="24"
                  icon="UserPlusIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ data.doctorCount }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                Doctors
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>


        <!-- Pharmacy -->
        <b-col
          xl="3"
          sm="3"
          class="mb-2 mb-xl-0"
        >
          <b-media no-body>
            <b-media-aside
              class="mr-2"
            >
              <b-avatar
                size="48"
                variant="light-success"
              >
                <feather-icon
                  size="24"
                  icon="PocketIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ data.pharmacyCount}}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                Pharmacies
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>


        <!-- Medical Rep -->
        <b-col
          xl="3"
          sm="3"
          class="mb-2 mb-xl-0"
        >
          <b-media no-body>
            <b-media-aside
              class="mr-2"
            >
              <b-avatar
                size="48"
                variant="light-warning"
              >
                <feather-icon
                  size="24"
                  icon="UsersIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ data.medicalRepCount}}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                MedicalReps
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>

         

      </b-row>
    </b-card-body>
  </b-card>
  
  </div>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody,BDropdownItem , BDropdown,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BDropdownItem,
    BDropdown
  },
  props: {
    data: {
      type: Object,
      default: () => [],
    },
  },
}
</script>
