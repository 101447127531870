<template>

<b-overlay
    :show="isLoading"
    rounded="sm"
>

<section id="dashboard-ecommerce" v-if="statisticsData">
     <b-row class="match-height">

     <!-- statistics card -->
      <b-col xl="8"  md="12"   >
        <statistics :data="statisticsData" />
      </b-col>
     <!-- End statistics card -->

    <!-- order chart card -->
      <b-col xl="4"  md="12" >
        <visit-statistic :data="visitStatistic" @filterDate="filterDate" />
      </b-col>
    <!-- End order chart card -->
    </b-row>


    <b-row>
        <!-- Transaction Card -->
      <b-col  lg="4" md="6"  >
        <transaction-order :data="transactionData" />
      </b-col>
      <!--/ End Transaction Card -->


      <!-- custoemr chart card -->
        <b-col lg="8" md="12">
            <region :regions="regions"></region>
            <!-- <customer-chart /> -->
        </b-col>
      <!-- End custoemr chart card -->

    </b-row>

    <b-row>
        <!-- order chart card -->
      <b-col
        lg="12"
        md="12"
      >
        <order-chart :data="data.transactionData" />
      </b-col>
      <!--/ End Order chart-->

    </b-row>

</section>

</b-overlay>
</template>

<script>
import statistics from './components/statistics.vue'
import VisitStatistic from './components/visits.vue'
import transactionOrder from './components/transaction.vue'
import orderChart from './components/orderChart.vue'
import customerChart from './components/customerChart.vue'
import { BRow, BCol } from 'bootstrap-vue'
import Customer from '../customer/index.vue'
import Region from './components/region.vue'

import {BOverlay} from 'bootstrap-vue'
import DataService  from './data/services'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default {
    components: {
    BRow,
    BCol,
    statistics,
    orderChart,
    VisitStatistic,
    transactionOrder,
    customerChart,
    Customer,
    Region,

    ToastificationContent,
    BOverlay
},
    data() {
        return {
            fromDate: '',
            isLoading: '',
            statisticsData: {},
            regions: [],
            visitStatistic: {
                 title: 'Support Tracker',
                lastDays: ['Last 28 Days', 'Last Month', 'Last Year'],
                totalTicket: 163,
                newTicket: 29,
                openTicket: 63,
                responseTime: 1,
                supportTrackerRadialBar: {
                    series: [86],
                },
            },
            transactionData: [
                    {
                    mode: 'All Order',
                    types: 'Starbucks',
                    avatar: 'PocketIcon',
                    avatarVariant: 'light-primary',
                    payment: '',
                    deduction: false,
                    },
                    {
                    mode: 'Pendding',
                    types: 'Add Money',
                    avatar: 'ClockIcon',
                    avatarVariant: 'light-warning',
                    payment: '',
                    deduction: false,
                    },
                    {
                    mode: 'Complate',
                    types: 'Add Money',
                    avatar: 'CheckIcon',
                    avatarVariant: 'light-info',
                    payment: '',
                    deduction: false,
                    },
                    {
                    mode: 'cancel',
                    types: 'Ordered Food',
                    avatar: 'XCircleIcon',
                    avatarVariant: 'light-danger',
                    payment: '',
                    deduction: true,
                    },
                    
                ],
            data: {
                statistics: [],

                transactionData: [
                    {
                    mode: 'All Order',
                    types: 'Starbucks',
                    avatar: 'PocketIcon',
                    avatarVariant: 'light-primary',
                    payment: '-$74',
                    deduction: true,
                    },
                    {
                    mode: 'Pendding',
                    types: 'Add Money',
                    avatar: 'ClockIcon',
                    avatarVariant: 'light-warning',
                    payment: '+$480',
                    deduction: false,
                    },
                    {
                    mode: 'Complate',
                    types: 'Add Money',
                    avatar: 'CheckIcon',
                    avatarVariant: 'light-info',
                    payment: '+$480',
                    deduction: false,
                    },
                    {
                    mode: 'cancel',
                    types: 'Ordered Food',
                    avatar: 'XCircleIcon',
                    avatarVariant: 'light-danger',
                    payment: '-$23',
                    deduction: true,
                    },
                    {
                    mode: 'Confirm',
                    types: 'Refund',
                    avatar: 'TrendingUpIcon',
                    avatarVariant: 'light-success',
                    payment: '+$98',
                    deduction: false,
                    },
                ],
            }
        }
    },

    methods: {
        prepareData(){
            const today = new Date();
            const date = today.getFullYear() + '-' + (today.getMonth()+1) + '-' + today.getDate();
            return {
                fromDate: this.fromDate,
	            toDate: date
            }
        },
        getData(page){
            this.isLoading = true;
            DataService.getAll(this.prepareData())
            .then(response => {
                this.isLoading = false

                
                this.statisticsData = response.data.data.count


                this.visitStatistic.totalTicket = response.data.data.visits.all
                this.visitStatistic.newTicket = response.data.data.visits.pendingOrder
                this.visitStatistic.openTicket = response.data.data.visits.complete
                this.visitStatistic.responseTime = response.data.data.visits.inComplete
                
                this.visitStatistic.supportTrackerRadialBar.series = this.calclatePercentage(response.data.data.visits.all, response.data.data.visits.complete)
                
                this.regions =  response.data.data.regions

                // transactionData
                this.transactionData[0].payment = response.data.data.visitStatistic.allOrder
                this.transactionData[1].payment = response.data.data.visitStatistic.pendingOrder
                this.transactionData[2].payment = response.data.data.visitStatistic.completOrder
                this.transactionData[3].payment = response.data.data.visitStatistic.canceleOrder
                
                
                this.$toast({
                    component: ToastificationContent,
                    props: {
                    title: 'successfully',
                    icon: 'BellIcon',
                    text: response.data.responseDescription,
                    variant: 'success',
                    },
                })
            })
        },
        filterDate(val){
            this.fromDate = val
            this.getData()
        },

        calclatePercentage(all, compalet){
            if (all == 0) {
                return [0]
            }
            return [Math.round((compalet / all) * 100)]
        },
        getLastDate(day) {
            const now = new Date();
            const date = new Date(now.getFullYear(), now.getMonth(), now.getDate() - day);
            return  date.getFullYear() + '-' + (date.getMonth()+1) + '-' + date.getDate();
        }
    },
    mounted() {

        
        this.fromDate = this.getLastDate(7)
        
        this.getData()
    },
}
</script>